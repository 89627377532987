/* .gradient-custom-2 {
  background: #fccb90;

  background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);

  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
} */

/* .authFormWrap .form-control {
   border: 1px solid #cecece;
 }

 .mainContainerBox {
   height: calc(100vh - 58px);
   margin-top: 58px;
 }

 .monthlyPriceList {
   justify-content: center;
 }

 .authFormWrap input::placeholder,
 .authFormWrap textarea::placeholder {
   color: #7f7f7f;
 } */

/* .authFormWrap input,
.authFormWrap textarea {
  border: 1px solid #cecece;
  outline: none;
  box-shadow: none;
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
} */

/* 
@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}

@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
} */

.appLoader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffffe0;
  z-index: 9999;
}

.appLoader img {
  width: 130px;
}

/* body {
  background-color: #fbfbfb;
}

@media (min-width: 991.98px) {
  main {
    padding-left: 240px;
  }
} */

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}

.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.list-group-item {
  border: none !important;
}

/* .otpMainBox {
  display: flex;
}

.otpMainBox input {
  margin: 0 10px;
  text-align: center;
  height: 65px;
  font-size: 20px;
}

.otpMainBox input[type=number]::-webkit-inner-spin-button,
.otpMainBox input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.otpMainBox input:first-child {
  margin-left: 0;
}

.otpMainBox input:last-child {
  margin-right: 0;
}

*/
.resendBtnBox {
  display: flex;
  align-items: center;
}

.resendText {
  font-size: 14px;
}

.resendLinkBtn {
  padding: 0;
  margin: 0 0 0 10px !important;
  font-size: 14px;
}

.resendLinkBtn:hover {
  background-color: transparent;
}

.readon {
  position: relative;
  display: inline-block !important;
  background: #f36233;
  padding: 14px 30px;
  line-height: normal;
  color: #ffffff !important;
  transition: all 0.3s ease 0s;
  border-radius: 30px;
  text-transform: capitalize !important;
  cursor: pointer;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
}

.readon:hover,
.readon:focus {
  background: #242526;
}

.inner {
  width: 100%;
  float: left;
  position: relative;
}

.pricingTable .holder {
  background: #fff;
  box-shadow: 1px 20px 12px -15px rgba(0, 0, 0, 0.2);
  padding: 40px 15px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: 0.5s ease;
}

.pricingTable .holder .hdng p {
  font-size: 28px;
  font-weight: bold;
  color: #242526;
}

.pricingTable .holder .img img {
  width: 70%;
}

.pricingTable .holder .price p {
  color: #f36233;
  margin-bottom: 25px;
}

.pricingTable .holder .price p b {
  font-size: 40px;
  font-weight: bold;
}

.pricingTable .holder .price p span {
  font-size: 18px;
}

.pricingTable .holder .info p {
  margin-bottom: 15px;
  color: #242526;
  font-weight: 14px;
}

.pricingTable .holder.active {
  background: #f36233;
}

.pricingTable .holder.active .hdng p,
.pricingTable .holder.active .price p,
.pricingTable .holder.active .info p {
  color: #fff;
}

.pricingTable .holder.active .readon {
  background: #fff;
  color: #f36233 !important;
}

.pricingTable .holder.active .readon:hover {
  background: #242526;
  color: #fff !important;
}

.pricingTable .tabsBtnHolder ul {
  float: left;
  display: block;
  width: 100%;
  max-width: 326px;
  border-radius: 1.6666666667rem;
  margin: 0px auto;
  margin-bottom: 40px;
  background: #f36233;
  text-align: center;
  position: relative;
}

.pricingTable .tabsBtnHolder ul li {
  width: calc(100% / 2);
  display: inline-block;
  transition: 0.4s ease;
}

.pricingTable .tabsBtnHolder ul li p {
  color: #fff;
  padding: 10px 15px;
  z-index: 10;
  position: relative;
  cursor: pointer;
}

.pricingTable .tabsBtnHolder ul li p.active {
  color: #f36233;
}

.pricingTable .tabsBtnHolder ul li.indicator {
  position: absolute;
  top: 50%;
  left: 2px;
  background: #fff;
  height: calc(100% - 4px);
  transform: translateY(-50%);
  border-radius: 1.5333333333rem;
  width: 161px;
  z-index: 9
}


.toogleListLi {
  padding: 5px 18px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
}

.toogleListLi.active {
  background-color: #ffbebe;
}

h3,
h4,
h5,
h6,
p {
  margin-bottom: initial;
}

.fa-lg {
  line-height: unset !important;
}

.incrDecrBtn {
  border: '1px solid #e0e0e0'
}

.stripeField {
  padding: 12px;
  border: 1px solid #cecece;
  border-radius: 5px;
  background: #fff;
  width: 100%;
}

.basicFields {
  border-radius: 5px;
  width: 100%;
}

.cvcdateWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardSelectBox {
  display: flex;
  align-items: center;
}

.cardSelectBox label {
  margin-left: 0;
}

.cardContentBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.btnBoxCheckout {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* PLANS LIST CSS */